export function parseRgbString(rgbString) { const rgbMatch = rgbString.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/); const rgbaMatch = rgbString.match(/rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)/); if (rgbMatch) {
    return { r: parseInt(rgbMatch[1], 10), g: parseInt(rgbMatch[2], 10), b: parseInt(rgbMatch[3], 10) };
} if (rgbaMatch) {
    return { r: parseInt(rgbaMatch[1], 10), g: parseInt(rgbaMatch[2], 10), b: parseInt(rgbaMatch[3], 10), a: parseFloat(rgbaMatch[4]) };
} throw new Error("Invalid RGB(A) string format"); }
export function rgbToString(_ref) { let { r, g, b, a, allowAlpha = true } = _ref; if (a && allowAlpha) {
    return "rgba(".concat(r, ", ").concat(g, ", ").concat(b, ", ").concat(a, ")");
} return "rgb(".concat(r, ", ").concat(g, ", ").concat(b, ")"); }
export function parseRgbStringToHex(rgbString) { let allowAlpha = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true; const rgbObject = parseRgbString(rgbString); return rgbToHex({ ...rgbObject, allowAlpha }); }
export function componentToHex(c) { var hex = c.toString(16); return hex.length === 1 ? "0" + hex : hex; }
export function rgbToHex(_ref2) { let { r, g, b, a, allowAlpha = true } = _ref2; if (a && allowAlpha) {
    const alpha = Math.round(a * 255);
    if (alpha === 255) {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }
    else {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b) + componentToHex(alpha);
    }
} return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b); }
export function hexToRgb(hex) {
    hex = hex.replace(/^#/, ''); // Check the length of the hex value to determine if it includes alpha
    if (hex.length === 6) {
        const bigint = parseInt(hex, 16);
        const r = bigint >> 16 & 255;
        const g = bigint >> 8 & 255;
        const b = bigint & 255;
        return { r, g, b };
    }
    else if (hex.length === 8) {
        const bigint = parseInt(hex, 16);
        const r = bigint >> 24 & 255;
        const g = bigint >> 16 & 255;
        const b = bigint >> 8 & 255;
        const a = (bigint & 255) / 255;
        return { r, g, b, a };
    }
    else {
        throw new Error("Invalid HEX string format");
    }
}
export function hexToRgbString(hex) { const { r, g, b, a } = hexToRgb(hex); if (a !== undefined) {
    return "rgba(".concat(r, ", ").concat(g, ", ").concat(b, ", ").concat(a, ")");
}
else {
    return "rgb(".concat(r, ", ").concat(g, ", ").concat(b, ")");
} }
export function hexToRgbaString(hex, alpha) {
    let r = 0, g = 0, b = 0; // 3 digits
    if (hex.length === 4) {
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
    } // 6 digits
    else if (hex.length === 7) {
        r = parseInt(hex[1] + hex[2], 16);
        g = parseInt(hex[3] + hex[4], 16);
        b = parseInt(hex[5] + hex[6], 16);
    }
    return "rgba(".concat(r, ", ").concat(g, ", ").concat(b, ", ").concat(alpha, ")");
} // export interface RGBA {
//     r: number;
//     g: number;
//     b: number;
//     a: number;
// }
// export interface ILinearGradient {
//     mode: "linear";
//     angle: number;
//     colorStops: IColorStop[];
// }
//
// export interface IRadialGradient {
//     mode: "radial";
//     angle: "circle";
//     colorStops: IColorStop[];
// }
//
// export type Gradient = ILinearGradient | IRadialGradient;
function rgbaToHex2(r, g, b, a) { const toHex = n => n.toString(16).padStart(2, '0'); const alpha = Math.round(a * 255); if (alpha === 255) {
    return "#".concat(toHex(r)).concat(toHex(g)).concat(toHex(b));
} return "#".concat(toHex(r)).concat(toHex(g)).concat(toHex(b)).concat(toHex(alpha)); }
export function parseGradient(gradient) { const linearGradientRegex = /linear-gradient\((\d+)deg,\s*((?:rgba?\(\d+,\s*\d+,\s*\d+(?:,\s*[\d.]+)?\)\s*\d+%?,?\s*)+)\)/; const radialGradientRegex = /radial-gradient\(\s*(circle|ellipse)?\s*at\s*(\d+)%\s+(\d+)%\s*,\s*((?:rgba?\(\d+,\s*\d+,\s*\d+(?:,\s*[\d.]+)?\)\s*\d+%?,?\s*)+)\)/; const conicGradientRegex = /conic-gradient\(\s*from\s*(\d+)deg\s*at\s*(\d+)%\s+(\d+)%\s*,\s*((?:rgba?\(\d+,\s*\d+,\s*\d+(?:,\s*[\d.]+)?\)\s*\d+%?,?\s*)+)\)/; let match = gradient.match(linearGradientRegex); let mode = "linear"; let colorsString = ""; if (!match) {
    match = gradient.match(radialGradientRegex);
    mode = "radial";
} if (!match) {
    match = gradient.match(conicGradientRegex);
    mode = "conic";
} if (!match) {
    throw new Error("Invalid gradient format");
} let angle; let shape = "circle"; let axios = { x: 50, y: 50 }; if (mode === "linear") {
    angle = parseInt(match[1], 10);
    colorsString = match[2];
}
else if (mode === "radial") {
    shape = match[1] || "circle";
    axios = { x: parseInt(match[2], 10), y: parseInt(match[3], 10) };
    colorsString = match[4];
}
else if (mode === "conic") {
    angle = parseInt(match[1], 10);
    axios = { x: parseInt(match[2], 10), y: parseInt(match[3], 10) };
    colorsString = match[4];
} const colorRegex = /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)\s*(\d+)%/g; const stops = []; let colorMatch; while ((colorMatch = colorRegex.exec(colorsString)) !== null) {
    const r = parseInt(colorMatch[1], 10);
    const g = parseInt(colorMatch[2], 10);
    const b = parseInt(colorMatch[3], 10);
    const a = colorMatch[4] ? parseFloat(colorMatch[4]) : 1;
    const position = parseInt(colorMatch[5], 10);
    stops.push({ color: rgbToHex({ r, g, b, a, allowAlpha: true }), position });
} if (mode === "linear") {
    return { mode, angle: angle, colorStops: stops };
}
else if (mode === "radial") {
    return { mode, shape, axios: axios, colorStops: stops };
}
else {
    return { mode, angle: angle, axios: axios, colorStops: stops };
} }
export function gradientToString(gradient) { const colorStopsString = gradient.colorStops.map(stop => "".concat(hexToRgbString(stop.color), " ").concat(stop.position, "%")).join(', '); switch (gradient.mode) {
    case 'linear': return "linear-gradient(".concat(gradient.angle, "deg, ").concat(colorStopsString, ")");
    case 'radial': return "radial-gradient(".concat(gradient.shape, " at ").concat(gradient.axios.x, "% ").concat(gradient.axios.y, "%, ").concat(colorStopsString, ")");
    case 'conic': return "conic-gradient(from ".concat(gradient.angle, "deg at ").concat(gradient.axios.x, "% ").concat(gradient.axios.y, "%, ").concat(colorStopsString, ")");
    default: throw new Error('Invalid gradient mode');
} } // export function parseGradient(gradient: string): Gradient {
//     const linearGradientRegex = /linear-gradient\((\d+)deg,\s*((?:rgba?\(\d+,\s*\d+,\s*\d+(?:,\s*[\d.]+)?\)\s*\d+%?,?\s*)+)\)/;
//     const radialGradientRegex = /radial-gradient\(\s*((?:rgba?\(\d+,\s*\d+,\s*\d+(?:,\s*[\d.]+)?\)\s*\d+%?,?\s*)+)\)/;
//
//     let match = gradient.match(linearGradientRegex);
//     let mode: "linear" | "radial" = "linear";
//
//     if (!match) {
//         match = gradient.match(radialGradientRegex);
//         mode = "radial";
//     }
//
//     if (!match) {
//         throw new Error("Invalid gradient format");
//     }
//
//     const angle = mode === "linear" ? parseInt(match[1], 10) : "circle";
//     const colorsString = mode === "linear" ? match[2] : match[1];
//     const colorRegex = /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)\s*(\d+)%/g;
//     const stops: IColorStop[] = [];
//
//     let colorMatch;
//     while ((colorMatch = colorRegex.exec(colorsString)) !== null) {
//         const r = parseInt(colorMatch[1], 10);
//         const g = parseInt(colorMatch[2], 10);
//         const b = parseInt(colorMatch[3], 10);
//         const a = colorMatch[4] ? parseFloat(colorMatch[4]) : 1;
//         const position = parseInt(colorMatch[5], 10);
//
//         stops.push({
//             color: rgbToString({ r, g, b, a, allowAlpha: true }),
//             position
//         });
//     }
//
//     if (mode === "linear") {
//         return {
//             mode,
//             angle: angle as number,
//             colorStops: stops
//         } as ILinearGradient;
//     } else {
//         return {
//             mode,
//             angle: angle as "circle",
//             colorStops: stops
//         } as IRadialGradient;
//     }
// }
// export function parseGradient(gradient: string): Gradient {
//     const linearGradientRegex = /linear-gradient\((\d+)deg,\s*((?:rgba?\(\d+,\s*\d+,\s*\d+(?:,\s*[\d.]+)?\)\s*\d+%?,?\s*)+)\)/;
//     const radialGradientRegex = /radial-gradient\(\s*((?:rgba?\(\d+,\s*\d+,\s*\d+(?:,\s*[\d.]+)?\)\s*\d+%?,?\s*)+)\)/;
//
//     let match = gradient.match(linearGradientRegex);
//     let mode: "linear" | "radial" = "linear";
//
//     if (!match) {
//         match = gradient.match(radialGradientRegex);
//         mode = "radial";
//     }
//
//     if (!match) {
//         throw new Error("Invalid gradient format");
//     }
//
//     const angle = mode === "linear" ? parseInt(match[1], 10) : "circle";
//     const colorsString = mode === "linear" ? match[2] : match[1];
//     const colorRegex = /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)\s*(\d+)%/g;
//     const stops: IColorStop[] = [];
//
//     let colorMatch;
//     while ((colorMatch = colorRegex.exec(colorsString)) !== null) {
//         const r = parseInt(colorMatch[1], 10);
//         const g = parseInt(colorMatch[2], 10);
//         const b = parseInt(colorMatch[3], 10);
//         const a = colorMatch[4] ? parseFloat(colorMatch[4]) : 1;
//         const position = parseInt(colorMatch[5], 10);
//
//         stops.push({
//             rgba: { r, g, b, a },
//             position
//         });
//     }
//
//     if (mode === "linear") {
//         return {
//             mode,
//             angle: angle as number,
//             colorStops: stops
//         } as ILinearGradient;
//     } else {
//         return {
//             mode,
//             angle: angle as "circle",
//             colorStops: stops
//         } as IRadialGradient;
//     }
// }
// export function parseGradient(gradient: string): Gradient {
//     const linearGradientRegex = /linear-gradient\((\d+deg),\s*((?:rgba?\(\d+,\s*\d+,\s*\d+(?:,\s*[\d.]+)?\)\s*\d+%?,?\s*)+)\)/;
//     const radialGradientRegex = /radial-gradient\(\s*((?:rgba?\(\d+,\s*\d+,\s*\d+(?:,\s*[\d.]+)?\)\s*\d+%?,?\s*)+)\)/;
//
//     let match = gradient.match(linearGradientRegex);
//     let mode: "linear" | "radial" = "linear";
//
//     if (!match) {
//         match = gradient.match(radialGradientRegex);
//         mode = "radial";
//     }
//
//     if (!match) {
//         throw new Error("Invalid gradient format");
//     }
//
//     const angle = mode === "linear" ? match[1] : "0deg";
//     const colorsString = mode === "linear" ? match[2] : match[1];
//     const colorRegex = /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)\s*(\d+)%/g;
//     const stops: ColorStop[] = [];
//
//     let colorMatch;
//     while ((colorMatch = colorRegex.exec(colorsString)) !== null) {
//         const r = parseInt(colorMatch[1], 10);
//         const g = parseInt(colorMatch[2], 10);
//         const b = parseInt(colorMatch[3], 10);
//         const a = colorMatch[4] ? parseFloat(colorMatch[4]) : 1;
//         const position = parseInt(colorMatch[5], 10);
//
//         stops.push({
//             rgba: { r, g, b, a },
//             position
//         });
//     }
//
//     return {
//         mode,
//         angle,
//         stops
//     };
// }
