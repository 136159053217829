import axios from "axios";
import UserService from "./UserService";
import ProfileService from "./ProfileService";
import AuthService from "./AuthService";
import TileService from "./TileService";
import AccountService from "./AccountService";
import AppearanceService from "./AppearanceService";
import CardService from "./CardService";
import AdminService from "./AdminService";
class Services {
    constructor(axiosInstance) { this.axiosInstance = axiosInstance; this.admin = void 0; this.users = void 0; this.profiles = void 0; this.auth = void 0; this.account = void 0; this.tiles = void 0; this.appearances = void 0; this.cards = void 0; this.admin = new AdminService(axiosInstance); this.users = new UserService(axiosInstance); this.profiles = new ProfileService(axiosInstance); this.auth = new AuthService(axiosInstance); this.account = new AccountService(axiosInstance); this.tiles = new TileService(axiosInstance); this.appearances = new AppearanceService(axiosInstance); this.cards = new CardService(axiosInstance); } // async get(url: string, args?: AxiosRequestConfig): Promise<any> {
}
const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL, // baseURL: "https://api.yoocard.usermd.net/api/v1",
    headers: { withCredentials: true } }); // axiosInstance.interceptors.response.use(
//     response => response,
//     async err => {
//         const axiosError = err as AxiosError;
//         if (axiosError.response?.data) {
//             console.log({...axiosError.response.data})
//             // return Promise.reject(json({...axiosError.response.data}, {status: axiosError.response.status}));
//             throw json({...axiosError.response.data}, {status: axiosError.response.status});
//         }
//         return Promise.reject(json({detail: "Server error"}, {status: 500}))
//         // if (!error.response || !(error.response.data instanceof Object)) {
//         //     return Promise.reject(json({detail: "Server error"}, {status: 500}))
//         // }
//         // // return Promise.reject(error);
//         // console.log("axiosInstance use:", error.response.status)
//         // return Promise.reject(json({...error.response.data}, {status: error.response.status}));
//         // return json({...error.response.data}, {status: error.status});
//     },
// )
const services = new Services(axiosInstance);
export default services;
