// export const requireMinLength = (value: string, length: number): boolean => value.trim().length > length;
//
// export const requireMaxLength = (value: string, length: number): boolean => value.trim().length < length;
class StringValidation {
    static lessThan(value, length) { return value.trim().length < length; }
    static lessOrEqual(value, length) { return value.trim().length <= length; }
    static greaterThan(value, length) { return value.trim().length > length; }
    static greaterOrEqual(value, length) { return value.trim().length >= length; }
    static equals(value, length) { return value.trim().length === length; }
    static matchesPattern(value, pattern) { return pattern.test(value); }
    static isEmpty(value) { return value.trim().length === 0; }
    static isNotEmpty(value) { return value.trim().length > 0; }
    static startsWith(value, searchString) { return value.trim().startsWith(searchString); }
    static endsWith(value, searchString) { return value.trim().endsWith(searchString); } // Możesz dodać więcej metod walidacyjnych tutaj
}
export default StringValidation;
