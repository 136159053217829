class ProfileService {
    constructor(axiosInstance) { this.axiosInstance = axiosInstance; this.config = void 0; this.config = { headers: { "Accept": "application/json" }, withCredentials: true }; }
    async getOneById(userId) { return this.axiosInstance.get("/profiles/".concat(userId)); }
    async getOneByUsername(username) {
        try {
            const response = await this.axiosInstance.get("/profiles/".concat(username), this.config);
            return response.data;
        }
        catch (error) {
            var _axiosError$response;
            const axiosError = error;
            if ((_axiosError$response = axiosError.response) !== null && _axiosError$response !== void 0 && _axiosError$response.data) {
                if (axiosError.response.status === 404) { // throw axiosError.response.data
                    throw { detail: "Profil o nazwie ".concat(username, " nie istnieje."), status: 404 }; // throw json({detail: `Profil o nazwie ${username} nie istnieje.`}, {status: 404})
                    // throw Error(`Profil o nazwie ${username} nie istnieje.`)
                }
            }
            throw Error("Błąd serwera.");
        }
    }
    async getMe() {
        try {
            const response = await this.axiosInstance.get("/profiles", this.config);
            return response.data;
        }
        catch (error) {
            var _axiosError$response2;
            const axiosError = error;
            if ((_axiosError$response2 = axiosError.response) !== null && _axiosError$response2 !== void 0 && _axiosError$response2.data) {
                if (axiosError.response.status === 404) { // throw axiosError.response.data
                    throw { detail: "Profil o nazwie nie istnieje.", status: 404 }; // throw json({detail: `Profil o nazwie ${username} nie istnieje.`}, {status: 404})
                    // throw Error(`Profil o nazwie ${username} nie istnieje.`)
                }
            }
            throw Error("Błąd serwera.");
        }
    }
}
export default ProfileService;
