import { json } from "react-router-dom";
import CardAnalyticsService from "./CardServices/CardAnalyticsServices";
class CardService {
    constructor(axiosInstance) { this.axiosInstance = axiosInstance; this.analytics = void 0; this.config = void 0; this.analytics = new CardAnalyticsService(axiosInstance); this.config = { headers: { "Accept": "application/json" }, withCredentials: true }; }
    async getOneById(cardId) { try {
        const result = await this.axiosInstance.get("/cards/".concat(cardId), this.config);
        return result.data;
    }
    catch (err) {
        var _axiosError$response;
        const axiosError = err;
        if ((_axiosError$response = axiosError.response) !== null && _axiosError$response !== void 0 && _axiosError$response.data) {
            throw json({ ...axiosError.response.data }, { status: axiosError.response.status });
        }
        throw json({ detail: "Something went wrong." }, { status: 500 });
    } }
    async getMulti(params) { try {
        const result = await this.axiosInstance.get("/cards", { ...this.config, params: params });
        return result.data;
    }
    catch (err) {
        var _axiosError$response2;
        const axiosError = err;
        if ((_axiosError$response2 = axiosError.response) !== null && _axiosError$response2 !== void 0 && _axiosError$response2.data) {
            throw json({ ...axiosError.response.data }, { status: axiosError.response.status });
        }
        throw json({ detail: "Something went wrong." }, { status: 500 });
    } }
    async getProfile(cardId) {
        try {
            const response = await this.axiosInstance.get("/cards/".concat(cardId, "/profile"), this.config);
            return response.data;
        }
        catch (error) {
            var _axiosError$response3;
            const axiosError = error;
            if ((_axiosError$response3 = axiosError.response) !== null && _axiosError$response3 !== void 0 && _axiosError$response3.data) {
                if (axiosError.response.status === 404) { // throw axiosError.response.data
                    throw { detail: "Profile not found", status: 404 }; // throw json({detail: `Profil o nazwie ${username} nie istnieje.`}, {status: 404})
                    // throw Error(`Profil o nazwie ${username} nie istnieje.`)
                }
            }
            throw Error("Błąd serwera.");
        }
    }
}
export default CardService;
