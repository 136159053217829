import axios from "axios";
import { json } from "react-router-dom";
class AuthService {
    constructor(axiosInstance) { this.axiosInstance = axiosInstance; this.config = void 0; this.config = { headers: { "Content-Type": "application/x-www-form-urlencoded", "Accept": "application/json" }, withCredentials: true }; } // async login(data: FormData): Promise<AxiosResponse<{ detail: string; }, any>> {
    async login(data) {
        try {
            const response = await this.axiosInstance.post("/login", data, this.config);
            return { ...response.data, status: 200 };
        }
        catch (error) {
            if (axios.isAxiosError(error)) {
                var _error$response;
                if (((_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status) === 401) {
                    throw json({ detail: "Incorrect login details", status: 401 }, { status: 401 });
                }
            }
            throw json({ detail: "Unfortunately, something went wrong", status: 401 }, { status: 500 });
        } // try {
        //     return await this.axiosInstance.post<{ detail: string }>("/auth/login", data, this.config);
        // } catch (error) {
        //     if (axios.isAxiosError(error)) {
        //         // throw error.response
        //         throw new Response(JSON.stringify({detail: "Something went wrong."}), {status: 500})
        //     }
        //
        //     throw new Response(JSON.stringify({detail: "Something went wrong."}), {status: 500})
        // }
        // async login(data: FormData): Promise<{ detail: string }> {
        // try {
        //     const response = await this.axiosInstance.post<IAuthData>("/auth/login", data, {
        //         headers: {
        //             "Content-Type": "application/x-www-form-urlencoded",
        //             "Accept": "application/json",
        //         },
        //         withCredentials: true
        //     });
        //     // return response.data; TODO: Uncomment in qu domain
        //     return {detail: "Logowanie przebiegło pomyśnie."}, {status: response.status})
        // } catch (err) {
        //     const axiosError = err as AxiosError;
        //     if (axiosError.response?.data) {
        //         if (axiosError.response.status === 401) {
        //             throw json({detail: "Nie poprawne dane logowania"}, {status: 401})
        //         }
        //     }
        //     throw json({detail: "Błąd serwera."}, {status: 500})
        // }
    }
    async logout() { try {
        const response = await this.axiosInstance.post("/logout", {}, this.config);
        return response.data;
    }
    catch (err) {
        throw Error("Błąd serwera.");
    } }
    async register(data) {
        const config = { headers: { "Content-Type": "application/x-www-form-urlencoded", "Accept": "application/json" } };
        try {
            const response = await this.axiosInstance.post("/register", data, config);
            return response.data;
        }
        catch (err) {
            var _axiosError$response;
            const axiosError = err;
            if ((_axiosError$response = axiosError.response) !== null && _axiosError$response !== void 0 && _axiosError$response.data) {
                if (axiosError.response.status === 409) {
                    throw Error("Użytkownik o podanych danych już istnieje.");
                }
            }
            throw Error("Błąd serwera."); // console.log(error.status)
            // if (!error.response || !(error.response.data instanceof Object)) {
            //     throw json({detail: "Something went wrong."}, {status: 500})
            // }
            // if (error.response.status === 409) {
            //     console.log(error.response)
            //     throw json({...error.response.data}, {status: 400});
            // }
            // throw json({detail: "Unexpected error"}, {status: 500})
        }
    } // isAuthentication(): boolean {
    //     const token = document.cookie.split(';').some((item) => item.trim().startsWith('access_token='));
    //     return !!token;
    // }
    async verify(token) { try {
        const response = await this.axiosInstance.get("/verify_email/".concat(token), this.config);
        return response.data;
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            var _error$response2;
            if (((_error$response2 = error.response) === null || _error$response2 === void 0 ? void 0 : _error$response2.status) === 401) {
                throw json({ detail: "Nie poprawne dane logowania", status: 401 }, { status: 401 });
            }
        }
        throw json({ detail: "Niestety coś poszło nie tak", status: 401 }, { status: 500 });
    } }
    async resendVerify() { try {
        const response = await this.axiosInstance.post("/resend_verify_email", {}, this.config);
        return response.data;
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            var _error$response3;
            if (((_error$response3 = error.response) === null || _error$response3 === void 0 ? void 0 : _error$response3.status) === 401) {
                throw json({ detail: "Nie poprawne dane logowania", status: 401 }, { status: 401 });
            }
        }
        throw json({ detail: "Niestety coś poszło nie tak", status: 401 }, { status: 500 });
    } }
}
export default AuthService;
