import axios from "axios";
import { json } from "react-router-dom";
class AdminUserService {
    constructor(axiosInstance) { this.axiosInstance = axiosInstance; this.config = void 0; this.config = { headers: { "Accept": "application/json" }, withCredentials: true }; } // public async getMulti(params?: IUserQuerySearch): Promise<IPage<IUser[]> | IPage<Partial<IUser[]>>> {
    // public async getMulti<K extends keyof IUser>(params?: IUserQuerySearch): Promise<IPage<IUser[]> | IPage<Partial<IUser[]>> | IPage<Partial<Pick<IUser, K>[]>>> {
    async getMulti(params) { try {
        const response = await this.axiosInstance.get("/admin/users", { params: { ...params }, ...this.config });
        return response.data;
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            var _error$response;
            if (((_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status) === 401) {
                throw json({ detail: "Incorrect login details", status: 401 }, { status: 401 });
            }
        }
        throw json({ detail: "Unfortunately, something went wrong", status: 401 }, { status: 500 });
    } }
    async create(data) { const config = { headers: { "Content-Type": "application/x-www-form-urlencoded", "Accept": "application/json" }, withCredentials: true }; try {
        const response = await this.axiosInstance.post("/admin/users", data, config);
        return response.data;
    }
    catch (err) {
        var _axiosError$response;
        const axiosError = err;
        if ((_axiosError$response = axiosError.response) !== null && _axiosError$response !== void 0 && _axiosError$response.data) {
            if (axiosError.response.status === 409) {
                throw Error("Użytkownik o podanych danych już istnieje.");
            }
        }
        throw Error("Błąd serwera.");
    } }
}
export default AdminUserService;
