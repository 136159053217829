import { styled } from "@mui/system";
import { Dialog } from "@mui/material";
const CustomDialog = styled(Dialog)(_ref => {
    let { theme } = _ref;
    return { '& .MuiDialogContent-root': { padding: theme.spacing(2) }, '& .MuiDialogActions-root': { padding: theme.spacing(1) }, '& .MuiPaper-root': { // maxWidth: '100%',
        // [theme.breakpoints.up('csm')]: {
        //     minWidth: '512px',
        //     minHeight: '512px'
        // },
        // maxWidth: fullScreen ? "100%" : "100%"
        } };
});
export default CustomDialog;
