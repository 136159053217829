import { redirect } from "react-router-dom";
import services from "../../../services/Services";
import i18n from "../../../i18nConfig";
export const newUserAction = async (_ref) => { var _resData; let { request } = _ref; const formData = await request.formData(); let resData; const data = { fullName: formData.get("fullName"), username: formData.get("username"), password: formData.get("password"), email: formData.get("email"), gender: formData.get("gender"), phoneNumber: formData.get("phoneNumber") }; try {
    resData = await services.admin.users.create(data);
}
catch (err) {
    const error = err;
    if (error.status === 401) {
        return redirect("/".concat(i18n.t("paths.login.index")));
    }
} return (_resData = resData) !== null && _resData !== void 0 ? _resData : { detail: "An error occurred." }; };
