import StringValidation from "./global";
import services from "../services/Services";
export const isUserUsernameValid = value => { const regex = /^[a-zA-Z0-9_.-]+$/; return value.trim().length >= 2 && value.trim().length <= 64 && regex.test(value); };
export const isUserFullNameValid = value => value.trim().length >= 2 && value.trim().length <= 64;
export const isUserEmailValid = value => { return Boolean(String(value).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)); };
export const isUserPasswordValid = value => {
    const v = value.trim(); // const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,64}$/;
    // const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,32}$/;
    const regex = /^[a-zA-Z0-9!@#$%^&*()_+=\-{}\[\];':",./<>?].{8,32}$/;
    return regex.test(v);
};
export const isUserPasswordReplacedValid = (value1, value2) => { return value1 === value2 && isUserPasswordValid(value1) && isUserPasswordValid(value2); };
export const isUserLoginValid = value => { const regex = /^.{2,}$/; return regex.test(value); }; // export const userLoginRequireMinLength(value)
// export const isUserPhoneNumberValid = (value: string): boolean => {
// 	const regex = /^\+?[1-9]\d{1,14}$/
// 	return regex.test(value);
// }
const createRegexFromFormat = format => { const escapedFormat = format.replace(/ /g, '').replace(/-/g, '').replace(/^\+/, '').replace(/\./g, '\\d'); return new RegExp('^' + escapedFormat + '$'); };
const createRegexFromFormatPrefix = format => { const escapedFormat = format.replace(/ /g, '').replace(/-/g, '').replace(/^\+/, '').replace(/\./g, '\\d'); return new RegExp('^' + escapedFormat + '$'); };
export const isUserPhoneNumberValid = (phoneNumber, format) => { const regexPrefix = createRegexFromFormatPrefix(format); const regex = createRegexFromFormat(format); return regex.test(phoneNumber); };
export const isUserPhoneNumberValidOrEmpty = (phoneNumber, format) => {
    if (!phoneNumber.trim())
        return true;
    if (format === "")
        return true;
    let dotCount = 0;
    for (let i = 0; i < format.length; i++) {
        if (format[i] === ' ')
            break;
        if (format[i] === '.')
            dotCount++;
    }
    if (phoneNumber.length === dotCount)
        return true;
    return isUserPhoneNumberValid(phoneNumber, format);
};
export const userPhoneNumberValidation = (phoneNumber, format) => { if (!phoneNumber.trim())
    return undefined; if (format === "")
    return undefined; let dotCount = 0; for (let i = 0; i < format.length; i++) {
    if (format[i] === " ")
        break;
    if (format[i] === ".")
        dotCount++;
} if (phoneNumber.length === dotCount)
    return undefined; const regexPrefix = createRegexFromFormatPrefix(format); const regex = createRegexFromFormat(format); if (!regex.test(phoneNumber)) {
    return "Please enter correct phone number";
} return undefined; };
export const userLoginValidation = value => { if (StringValidation.isEmpty(value)) {
    return "Please enter an email or username";
} if (StringValidation.lessThan(value, 2)) {
    return "Login must be 2 characters or longer";
} if (StringValidation.greaterThan(value, 64)) {
    return "Login cannot be longer than 64 characters";
} return undefined; };
export const userFullNameValidation = value => { if (StringValidation.isEmpty(value)) {
    return "Please enter a full name";
} if (StringValidation.lessThan(value, 1)) {
    return "Full name must be 2 characters or longer";
} if (StringValidation.greaterThan(value, 64)) {
    return "Full name cannot be longer than 64 characters";
} return undefined; };
export const userUsernameValidation = value => { if (StringValidation.isEmpty(value)) {
    return "Please enter a username";
} if (StringValidation.lessThan(value, 1)) {
    return "Username must be 2 characters or longer";
} if (StringValidation.greaterThan(value, 64)) {
    return "Username cannot be longer than 64 characters";
} if (!/^[a-zA-Z0-9_.-]+$/.test(value)) {
    return "Username can only contain letters, numbers, dots, underscores and hyphens";
} return undefined; };
export const userUsernameCheckAvailable = async (value) => { if (value.trim() === "")
    return undefined; const { isAvailable } = await services.users.isUsernameAvailable(value); return isAvailable ? undefined : "Username is not available"; };
export const userPasswordValidation = value => { if (StringValidation.isEmpty(value)) {
    return "Please enter a password";
} if (StringValidation.lessThan(value, 2)) {
    return "Password must be 8 characters or longer";
} if (StringValidation.greaterThan(value, 32)) {
    return "Password cannot be longer than 64 characters";
} if (!/[a-z]/.test(value)) {
    return "Password must include at least one lowercase letter";
} if (!/[A-Z]/.test(value)) {
    return "Password must include at least one uppercase letter";
} if (!/[0-9]/.test(value)) {
    return "Password must include at least one number";
} if (!/[!@#$%^&*()_+=\-{}\[\];':",./<>?]/.test(value)) {
    return "Password must include at least one special character";
} return undefined; };
export const userReplacedPasswordValidation = (correctValue, value) => { if (StringValidation.isEmpty(value)) {
    return "Please confirm your password";
} if (correctValue !== value) {
    return "Passwords do not match";
} return undefined; };
export const userEmailValidation = value => { if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
    return "Entered email is invalid";
} return undefined; };
