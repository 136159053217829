import { useEffect, useReducer } from "react";
const initialState = { value: "", require: false, isTouched: false, isActive: false, isAvailable: true, errorAvailableMessage: undefined };
const inputStateReducer = (state, action) => { switch (action.type) {
    case "CHANGE": return { ...state, value: action.value || "", isActive: true };
    case "BLUR": return { ...state, isTouched: true, isActive: false };
    case "FOCUS": return { ...state, isActive: true };
    case "AVAILABLE": return { ...state, isAvailable: action.isAvailable, errorAvailableMessage: action.errorAvailableMessage };
    case "REQUIRE": return { ...state, require: action.require || false };
    case "RESET": return { ...initialState };
    default: return { ...state };
} };
const useInput = _ref => {
    let { initialValue = "", validator, require = true, checkAvailability, initialIsTouch = false, autoAddPrefix } = _ref;
    const [inputState, dispatch] = useReducer(inputStateReducer, { ...initialState, value: initialValue ? initialValue : "", isTouched: initialIsTouch, require: require });
    let isValid = true;
    let errorMessage = inputState.errorAvailableMessage ? inputState.errorAvailableMessage : undefined;
    useEffect(() => { if (inputState.value.trim() === "") {
        if (!inputState.isAvailable) {
            dispatch({ type: "AVAILABLE", isAvailable: true, errorAvailableMessage: undefined });
        }
    } ; const timer = setTimeout(async () => { if (checkAvailability) {
        errorMessage = await checkAvailability(inputState.value);
        dispatch({ type: "AVAILABLE", isAvailable: !errorMessage, errorAvailableMessage: errorMessage });
    } }, 500); return () => { clearTimeout(timer); }; }, [inputState.value]);
    if (inputState.require) {
        if (validator && !inputState.errorAvailableMessage) {
            errorMessage = validator(inputState.value);
        }
    } // if (errorMessage || !inputState.isAvailable || (emptyAsInvalid && inputState.value.trim().length === 0)) {
    //     isValid = false;
    // }
    if (errorMessage || !inputState.isAvailable) {
        isValid = false;
    }
    let hasError = !isValid && inputState.isTouched; // if (missErrorIfEmpty && !inputState.value.trim().length) {
    //     errorMessage = undefined;
    //     hasError = false;
    // }
    const handleInputChange = async (event) => { if (autoAddPrefix && event.target.value.trim().length > inputState.value.trim().length && event.target.value.trim().length === 1) {
        dispatch({ type: "CHANGE", value: autoAddPrefix + event.target.value });
        return;
    } dispatch({ type: "CHANGE", value: event.target.value }); };
    const handleInputBlur = () => { dispatch({ type: "BLUR" }); };
    const handleInputFocus = () => { dispatch({ type: "FOCUS" }); };
    const setRequire = value => { dispatch({ type: "REQUIRE", require: value }); };
    const reset = () => { dispatch({ type: "RESET" }); };
    return { value: inputState.value, require: require, isTouched: inputState.isTouched, isActive: inputState.isActive, isValid, hasError, errorMessage, onChange: handleInputChange, onBlur: handleInputBlur, onFocus: handleInputFocus, setRequire, reset };
};
export default useInput;
