import { saveAs } from "file-saver";
export const generateVCF = _ref => {
    let { username, title, firstName, lastName, phoneTypePrimary, phonePrimary, emailTypePrimary, emailPrimary, profileURL } = _ref;
    let vcfContent = "BEGIN:VCARD\nVERSION:3.0";
    if (firstName && lastName) {
        vcfContent += "\nN:".concat(firstName, ";").concat(lastName, ";");
    }
    else if (firstName) {
        vcfContent += "\nN:".concat(firstName, ";");
    }
    else if (lastName) {
        vcfContent += "\nN:".concat(lastName, ";");
    }
    if (emailTypePrimary && emailPrimary) {
        vcfContent += "\nEMAIL;TYPE=".concat(emailTypePrimary.toLowerCase(), ",INTERNET:").concat(emailPrimary);
    }
    if (phoneTypePrimary && phonePrimary) {
        vcfContent += "\nTEL;TYPE=".concat(phoneTypePrimary.toLowerCase(), ":").concat(phonePrimary);
    }
    vcfContent += "\nURL;TYPE=yoocard:".concat(profileURL, "\nEND:VCARD"); // Utworzenie obiektu Blob z zawartością VCF
    const blob = new Blob([vcfContent], { type: 'text/vcard' }); // Użycie FileSaver do pobrania pliku
    saveAs(blob, "".concat(username, "-").concat(title, ".vcf")); // // Utworzenie obiektu Blob z zawartością VCF
    // const blob = new Blob([vcfContent], { type: 'text/vcard' });
    //
    // // Utworzenie URL do obiektu Blob
    // const blobUrl = window.URL.createObjectURL(blob);
    //
    // // Inicjowanie pobierania pliku
    // window.open(blobUrl); // Możesz również użyć location.href = blobUrl;
    //
    // // Opóźnienie i zwolnienie URL Blob
    // setTimeout(() => {
    // 	window.URL.revokeObjectURL(blobUrl);
    // }, 100); // Opóźnienie 100 ms
    // // Utworzenie obiektu Blob z zawartością VCF
    // const blob = new Blob([vcfContent], { type: 'text/vcard' });
    //
    // // Utworzenie linku do pobrania pliku
    // const downloadLink = document.createElement('a');
    // downloadLink.href = window.URL.createObjectURL(blob);
    // downloadLink.download = `${fullName}.vcf`; // Nazwa pliku
    //
    // // Dodanie linku do dokumentu i symulacja kliknięcia w celu pobrania pliku
    // document.body.appendChild(downloadLink);
    // downloadLink.click();
    //
    // // Usunięcie linku po pobraniu pliku
    // document.body.removeChild(downloadLink);
};
