import { useEffect, useReducer } from "react";
import checkFormValidity from "../validation/checkFormValidity"; // const initialState: InitialStateInterface<any, any, any, any> = {
//     value: "",
//     options: [],
//     isTouched: false,
//     isActive: false,
// }
const initialState = () => ({ value: "", inputValue: "", options: [], isTouched: false, isActive: false, loading: false });
const AutocompleteStateReducer = (state, action) => { switch (action.type) {
    case "INPUT_CHANGE": return { ...state, inputValue: action.inputValue || "", isActive: true, loading: true };
    case "CHANGE": return { ...state, value: action.value || "", loading: true };
    case "BLUR": return { ...state, isTouched: true, isActive: false };
    case "FOCUS": if (state.isTouched) {
        return { ...state, isActive: true };
    }
    else {
        return { ...state, isActive: true, loading: true };
    }
    case "SET_OPTIONS": return { ...state, options: action.options || [], loading: false };
    case "RESET": return { ...initialState() };
    default: return { ...state };
} }; // const useAutocomplete: UseAutocompleteInterface = ({
//     initialValue = "",
//     validationRules,
//     initialIsTouch = false,
//     fetchSuggestions,
// }) => {
const useAutocomplete = _ref => {
    let { initialValue, validationRules, initialIsTouch = false, fetchSuggestions } = _ref;
    const [autocompleteState, dispatch] = useReducer(AutocompleteStateReducer, { ...initialState(), value: initialValue, isTouched: initialIsTouch });
    const validationInfo = checkFormValidity({ validationRules: validationRules, value: autocompleteState.inputValue, options: autocompleteState.options });
    let isValid = !validationInfo.isError;
    let error = !isValid && autocompleteState.isTouched;
    const errorMessage = validationInfo.message;
    console.log(autocompleteState);
    useEffect(() => { if (!autocompleteState.loading)
        return; const timer = setTimeout(async () => { try {
        const options = await fetchSuggestions(autocompleteState.inputValue);
        dispatch({ type: "SET_OPTIONS", options });
    }
    catch (error) {
        console.error("Error fetching suggestions: ", error);
        dispatch({ type: "SET_OPTIONS", options: [] });
    } }, 500); return () => clearTimeout(timer); }, [autocompleteState.inputValue, autocompleteState.loading, fetchSuggestions]);
    const handleInputChange = (event, value, reason) => { dispatch({ type: "INPUT_CHANGE", inputValue: value }); };
    const handleAutocompleteChange = (event, value, reason, details) => dispatch({ type: "CHANGE", value: value });
    const handleAutocompleteBlur = () => dispatch({ type: "BLUR" });
    const handleAutocompleteFocus = () => dispatch({ type: "FOCUS" });
    const reset = () => dispatch({ type: "RESET" });
    return { value: autocompleteState.value, inputValue: autocompleteState.inputValue, options: autocompleteState.options, // isTouched: AutocompleteState.isTouched,
        // isActive: AutocompleteState.isActive,
        isValid, loading: autocompleteState.loading, error, errorMessage, onInputChange: handleInputChange, onChange: handleAutocompleteChange, onBlur: handleAutocompleteBlur, onFocus: handleAutocompleteFocus, reset };
};
export default useAutocomplete;
