const checkFormValidity = _ref => {
    let { validationRules, value, options } = _ref; // if (!validationRules) return;
    // if (value === null) return {isError: false};
    if (value === null) {
        if (validationRules.required) {
            return { isError: true, message: validationRules.required.message };
        }
    }
    if (validationRules.compare) {
        if (value !== validationRules.compare.value) {
            return { isError: true, message: validationRules.compare.message };
        }
    }
    if (validationRules.compareWithOptions && options) {
        if (options !== null && options !== void 0 && options.includes(value)) {
            return { isError: true, message: validationRules.compareWithOptions.message };
        }
    }
    if (typeof value === 'string') {
        var _validationRules$minL, _validationRules$maxL;
        if (validationRules.required && value.trim() === '') {
            return { isError: true, message: validationRules.required.message };
        }
        if ((_validationRules$minL = validationRules.minLength) !== null && _validationRules$minL !== void 0 && _validationRules$minL.value && value.length < validationRules.minLength.value) {
            return { isError: true, message: validationRules.minLength.message };
        }
        if ((_validationRules$maxL = validationRules.maxLength) !== null && _validationRules$maxL !== void 0 && _validationRules$maxL.value && value.length > validationRules.maxLength.value) {
            return { isError: true, message: validationRules.maxLength.message };
        }
    }
    if (validationRules.patterns && value) {
        for (const pattern of validationRules.patterns) { // Zakładając, że wartość jest typu string, a jeśli jest typu number, konwertujemy ją na string
            const stringValue = typeof value === 'string' ? value : value.toString(); // Tworzenie RegExp obiektu z wartości pattern.value, która może być już RegExp lub stringiem
            const regex = pattern.value instanceof RegExp ? pattern.value : new RegExp(pattern.value);
            if (!regex.test(stringValue)) {
                return { isError: true, message: pattern.message };
            }
        }
    }
    if (typeof value === 'number') {
        var _validationRules$minV, _validationRules$maxV;
        if ((_validationRules$minV = validationRules.minValue) !== null && _validationRules$minV !== void 0 && _validationRules$minV.value && value < validationRules.minValue.value) {
            return { isError: true, message: validationRules.minValue.message };
        }
        if ((_validationRules$maxV = validationRules.maxValue) !== null && _validationRules$maxV !== void 0 && _validationRules$maxV.value && value > validationRules.maxValue.value) {
            return { isError: true, message: validationRules.maxValue.message };
        }
    }
    if (options && validationRules.autocomplete) {
        if (validationRules.autocomplete.includedValue && !options.includes(value)) {
            return { isError: true, message: validationRules.autocomplete.includedValue.message };
        }
    }
    return { isError: false };
};
export default checkFormValidity;
