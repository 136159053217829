const loadFromLocalStorage = () => { try {
    const isUserAuthenticatedData = localStorage.getItem("isAuthenticated");
    let serializedState = undefined;
    if (isUserAuthenticatedData) {
        const isAuthenticated = JSON.parse(isUserAuthenticatedData);
        serializedState = { auth: { isAuthenticated } };
    }
    return serializedState;
}
catch (e) {
    console.error('Could not load state', e);
    return undefined;
} };
export const preloadedState = loadFromLocalStorage();
