import { useReducer, useState } from "react";
import checkFormValidity from "../validation/checkFormValidity";
const initialState = { value: "", isTouched: false, isActive: false };
const inputStateReducer = (state, action) => { switch (action.type) {
    case "CHANGE": return { ...state, value: action.value || "", isActive: true };
    case "BLUR": return { ...state, isTouched: true, isActive: false };
    case "FOCUS": return { ...state, isActive: true };
    case "RESET": return { ...initialState };
    default: return { ...state };
} };
const useInput = _ref => {
    let { initialValue = "", validationRules: validationRulesProp, initialIsTouch = false, autoAddPrefix } = _ref;
    const [inputState, dispatch] = useReducer(inputStateReducer, { ...initialState, value: initialValue ? initialValue : "", isTouched: initialIsTouch });
    const [validationRules, setValidationRules] = useState(validationRulesProp);
    const validationInfo = checkFormValidity({ validationRules: validationRules, value: inputState.value });
    let isValid = !validationInfo.isError;
    let error = !isValid && inputState.isTouched;
    const errorMessage = validationInfo.message;
    const handleInputChange = async (event) => { if (autoAddPrefix && event.target.value.trim().length > inputState.value.trim().length && event.target.value.trim().length === 1) {
        dispatch({ type: "CHANGE", value: autoAddPrefix + event.target.value });
        return;
    } dispatch({ type: "CHANGE", value: event.target.value }); };
    const handleInputBlur = () => { dispatch({ type: "BLUR" }); };
    const handleInputFocus = () => { dispatch({ type: "FOCUS" }); };
    const reset = () => { dispatch({ type: "RESET" }); };
    return { value: inputState.value, // isTouched: inputState.isTouched,
        // isActive: inputState.isActive,
        isValid, error, errorMessage, onChange: handleInputChange, onBlur: handleInputBlur, onFocus: handleInputFocus, setValidationRules, reset };
};
export default useInput;
