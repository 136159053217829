import { redirect } from "react-router-dom";
import services from "../../../services/Services";
import i18n from "../../../i18nConfig";
export const newCardAction = async (_ref) => { let { request } = _ref; const data = await request.json(); try {
    return await services.admin.cards.create(data);
}
catch (err) {
    const error = err;
    if (error.status === 401) {
        return redirect("/".concat(i18n.t("paths.login.index")));
    }
} return redirect("/".concat(i18n.t("paths.dashboard.index"))); };
