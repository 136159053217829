class AccountService {
    constructor(axiosInstance) { this.axiosInstance = axiosInstance; this.baseConfig = void 0; this.baseConfig = { headers: { "Accept": "application/json" }, withCredentials: true }; }
    async getInfo() {
        try {
            const response = await this.axiosInstance.get("/account", this.baseConfig);
            return response.data;
        }
        catch (error) {
            var _axiosError$response;
            const axiosError = error;
            if ((_axiosError$response = axiosError.response) !== null && _axiosError$response !== void 0 && _axiosError$response.data) {
                if (axiosError.response.status === 401) { // throw axiosError.response.data
                    throw { detail: "Brakiem autoryzacji" }; // throw json({detail: `Profil o nazwie ${username} nie istnieje.`}, {status: 404})
                    // throw Error(`Profil o nazwie ${username} nie istnieje.`)
                }
            }
            throw Error("Błąd serwera.");
        }
    }
    async uploadPicture(file, onUploadProgress) {
        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await this.axiosInstance.post("/account/picture", formData, { ...this.baseConfig, onUploadProgress: onUploadProgress });
            return response.data;
        }
        catch (err) {
            const error = err;
            if (!error.response || !(error.response.data instanceof Object)) {
                throw { detail: "Server error" };
            } // Możesz dodać więcej obsługi błędów specyficznych dla tego endpointu
            throw { detail: "Server error" };
        }
    } // async uploadPicture(file: File, onProgress: ({percentage, isUploaded}: IOnProgress) => void): Promise<{detail: string}> {
    //     const formData = new FormData();
    //     formData.append('file', file);
    //     try {
    //         const response = await this.axiosInstance.post(
    //             `/account/picture`,
    //             formData,
    //             {
    //                 ...this.baseConfig,
    //                 onUploadProgress: (progressEvent: AxiosProgressEvent) => {
    //                     const total = progressEvent.total || 1; // Zapobiegaj dzieleniu przez 0, ustawiając domyślnie na 1
    //                     const percentCompleted = Math.round((progressEvent.loaded * 100) / total);
    //                     // isUploaded(progressEvent.upload);
    //                     onProgress({percentage: percentCompleted, isUploaded: progressEvent.upload || false});
    //                 }
    //             }
    //         );
    //         return response.data;
    //     } catch (err) {
    //         const error = err as AxiosError;
    //         if (!error.response || !(error.response.data instanceof Object)) {
    //             throw {detail: "Server error"}
    //         }
    //         // Możesz dodać więcej obsługi błędów specyficznych dla tego endpointu
    //         throw {detail: "Server error"}
    //     }
    // }
    async deletePicture() {
        try {
            const response = await this.axiosInstance.delete("/account/picture", this.baseConfig);
            return response.data;
        }
        catch (err) {
            const error = err;
            if (!error.response || !(error.response.data instanceof Object)) {
                throw { detail: "Server error" };
            } // Możesz dodać więcej obsługi błędów specyficznych dla tego endpointu
            throw { detail: "Server error" };
        }
    }
}
export default AccountService;
