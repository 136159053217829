import { useReducer } from "react";
const initialState = { value: "", format: "", isTouched: false, isActive: false, ignore: false };
const inputStateReducer = (state, action) => { switch (action.type) {
    case "CHANGE": return { ...state, value: action.value || "", format: action.format || "", ignore: action.ignore, isActive: true };
    case "BLUR": return { ...state, isTouched: true, isActive: false };
    case "FOCUS": return { ...state, isActive: true };
    case "RESET": return { ...initialState };
    default: return { ...state };
} };
const useInputPhone = _ref => { let { initialValue = "", validator } = _ref; const [inputState, dispatch] = useReducer(inputStateReducer, { ...initialState, value: initialValue }); let valueIsValid = true; let errorMessage = undefined; if (validator && !inputState.ignore) {
    errorMessage = validator(inputState.value, inputState.format);
} const hasError = !errorMessage && inputState.isTouched; const handleInputChange = (value, data, event, formattedValue) => { if ("format" in data) {
    if (data.name === "Qatar") {
        dispatch({ type: "CHANGE", value: value, format: "", ignore: true });
    }
    else {
        dispatch({ type: "CHANGE", value: value, format: data.format, ignore: false });
    }
}
else {
    dispatch({ type: "CHANGE", value: value, format: "", ignore: false });
} }; const handleInputBlur = () => { dispatch({ type: "BLUR" }); }; const handleInputFocus = () => { dispatch({ type: "FOCUS" }); }; const reset = () => { dispatch({ type: "RESET" }); }; return { value: inputState.value, format: inputState.format, ignore: inputState.ignore, isTouched: inputState.isTouched, isActive: inputState.isActive, isValid: valueIsValid, hasError, errorMessage, handleInputChange, handleInputBlur, handleInputFocus, reset }; };
export default useInputPhone;
