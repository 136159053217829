import { json } from "react-router-dom";
class TileService {
    constructor(axiosInstance) { this.axiosInstance = axiosInstance; this.config = void 0; this.config = { headers: { "Accept": "application/json" }, withCredentials: true }; }
    async getOneById(tileId) { try {
        const result = await this.axiosInstance.get("/tiles/".concat(tileId), this.config);
        return result.data;
    }
    catch (err) {
        var _axiosError$response;
        const axiosError = err;
        if ((_axiosError$response = axiosError.response) !== null && _axiosError$response !== void 0 && _axiosError$response.data) {
            throw json({ ...axiosError.response.data }, { status: axiosError.response.status });
        }
        throw json({ detail: "Something went wrong." }, { status: 500 });
    } }
    async getIconByIconUrl(iconUrl) { const result = await this.axiosInstance.get(iconUrl); return result.data; }
    async getMulti(params) { try {
        const result = await this.axiosInstance.get("/tiles", { ...this.config, params: params });
        return result.data;
    }
    catch (err) {
        var _axiosError$response2;
        const axiosError = err;
        if ((_axiosError$response2 = axiosError.response) !== null && _axiosError$response2 !== void 0 && _axiosError$response2.data) {
            throw json({ ...axiosError.response.data }, { status: axiosError.response.status });
        }
        throw json({ detail: "Something went wrong." }, { status: 500 });
    } }
    async create(data) { const config = { headers: { "Accept": "application/json" }, withCredentials: true }; try {
        const result = await this.axiosInstance.post("/tiles", data, config);
        return result.data;
    }
    catch (err) {
        var _axiosError$response3;
        const axiosError = err;
        if ((_axiosError$response3 = axiosError.response) !== null && _axiosError$response3 !== void 0 && _axiosError$response3.data) {
            throw json({ ...axiosError.response.data }, { status: axiosError.response.status });
        }
        throw json({ detail: "Something went wrong." }, { status: 500 });
    } }
    async update(tileId, data) { try {
        const result = await this.axiosInstance.patch("/tiles/".concat(tileId), data, this.config);
        return result.data;
    }
    catch (err) {
        var _axiosError$response4;
        const axiosError = err;
        if ((_axiosError$response4 = axiosError.response) !== null && _axiosError$response4 !== void 0 && _axiosError$response4.data) {
            throw json({ ...axiosError.response.data }, { status: axiosError.response.status });
        }
        throw json({ detail: "Something went wrong." }, { status: 500 });
    } }
    async delete(tileId) { const config = { headers: { "Accept": "application/json" }, withCredentials: true }; try {
        const result = await this.axiosInstance.delete("/tiles/".concat(tileId), config);
        return result.data;
    }
    catch (err) {
        var _axiosError$response5;
        const axiosError = err;
        if ((_axiosError$response5 = axiosError.response) !== null && _axiosError$response5 !== void 0 && _axiosError$response5.data) {
            throw json({ ...axiosError.response.data }, { status: axiosError.response.status });
        }
        throw json({ detail: "Something went wrong." }, { status: 500 });
    } }
}
export default TileService;
