import { redirect } from "react-router-dom";
import services from "../../../services/Services";
import i18n from "i18next";
export const appearancePhotoAction = async (_ref) => {
    let { request } = _ref;
    try {
        if (request.method === "DELETE") {
            await services.account.deletePicture(); // return redirect(`${i18n.t("paths.dashboard.index")}/${i18n.t("paths.dashboard.appearance.index")}`);
            return redirect("..");
        }
    }
    catch (err) {
        const error = err;
        if (error.status === 401) {
            return redirect("/".concat(i18n.t("paths.login.index")));
        }
    }
    return null;
};
