import { useReducer, useState } from "react";
import checkFormValidity from "../validation/checkFormValidity";
const initialState = { value: null, isTouched: false, isActive: false };
const selectStateReducer = (state, action) => { switch (action.type) {
    case "CHANGE": return { ...state, value: action.value || "", isActive: true };
    case "BLUR": return { ...state, isTouched: true, isActive: false };
    case "FOCUS": return { ...state, isActive: true };
    case "RESET": return { ...initialState };
    default: return { ...state };
} };
const useSelect = _ref => {
    let { initialValue, validationRules: validationRulesProp, options, initialIsTouch = false } = _ref;
    const [selectState, dispatch] = useReducer(selectStateReducer, { ...initialState, value: initialValue !== null && initialValue !== void 0 ? initialValue : null, isTouched: initialIsTouch });
    const [validationRules, setValidationRules] = useState(validationRulesProp);
    const validationInfo = checkFormValidity({ validationRules: validationRules, value: selectState.value, options: options });
    let isValid = !validationInfo.isError;
    let error = !isValid && selectState.isTouched;
    const errorMessage = validationInfo.message;
    const handleInputChange = async (event) => { dispatch({ type: "CHANGE", value: event.target.value }); };
    const handleInputBlur = () => { dispatch({ type: "BLUR" }); };
    const handleInputFocus = () => { dispatch({ type: "FOCUS" }); };
    const reset = () => { dispatch({ type: "RESET" }); };
    return { value: selectState.value, options, // isTouched: inputState.isTouched,
        // isActive: inputState.isActive,
        isValid, error, errorMessage, onChange: handleInputChange, onBlur: handleInputBlur, onFocus: handleInputFocus, setValidationRules, reset };
};
export default useSelect;
