import { useReducer } from "react";
const initialState = { value: undefined, isTouched: false, isActive: false // isAvailable: true,
    // errorAvailableMessage: undefined
};
const SelectStateReducer = (state, action) => { switch (action.type) {
    case "CHANGE": return { ...state, value: action.value || undefined, isActive: true };
    case "BLUR": return { ...state, isTouched: true, isActive: false };
    case "FOCUS": return { ...state, isActive: true };
    case "RESET": return { ...initialState };
    default: return { ...state };
} };
const useSelectInput = _ref => { let { initialValue, validator, options } = _ref; const [SelectState, dispatch] = useReducer(SelectStateReducer, { ...initialState, value: initialValue }); let isValid = true; let errorMessage = undefined; if (validator) {
    errorMessage = validator(SelectState.value);
} if (errorMessage) {
    isValid = false;
} const hasError = !isValid && SelectState.isTouched; const handleSelectChange = async (option) => { dispatch({ type: "CHANGE", value: option }); }; const handleSelectBlur = () => { dispatch({ type: "BLUR" }); }; const handleSelectFocus = () => { dispatch({ type: "FOCUS" }); }; const reset = () => { dispatch({ type: "RESET" }); }; return { value: SelectState.value, options, isTouched: SelectState.isTouched, isActive: SelectState.isActive, isValid, hasError, errorMessage, onChange: handleSelectChange, onBlur: handleSelectBlur, onFocus: handleSelectFocus, reset }; };
export default useSelectInput;
