const generateIconUrl = url => {
    //     return null;
    // }
    const patterns = { facebook: /https?:\/\/(www\.)?facebook\.com/, instagram: /https?:\/\/(www\.)?instagram\.com/, tiktok: /https?:\/\/(www\.)?tiktok\.com/, twitter: /https?:\/\/(www\.)?twitter\.com/, tripadvisor: /https?:\/\/(www\.)?tripadvisor\.com/, discord: /https?:\/\/(www\.)?discord\.com/, linkedin: /https?:\/\/(www\.)?linkedin\.com/ // Możesz dodać więcej wzorców dla innych usług
    };
    const baseUrl = process.env.REACT_APP_BACKEND_URL_STATIC + "/tiles/icons/";
    switch (true) {
        case patterns.facebook.test(url): return baseUrl + "facebook.svg";
        case patterns.instagram.test(url): return baseUrl + "instagram.svg";
        case patterns.tiktok.test(url): return baseUrl + "tiktok.svg";
        case patterns.twitter.test(url): return baseUrl + "twitter.svg";
        case patterns.tripadvisor.test(url): return baseUrl + "tripadvisor.svg";
        case patterns.discord.test(url): return baseUrl + "discord.svg";
        case patterns.linkedin.test(url): return baseUrl + "linkedin.svg";
        default: return baseUrl + "hyperlink.svg";
    }
};
export default generateIconUrl;
