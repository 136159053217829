import { json } from "react-router-dom";
class AppearanceService {
    constructor(axiosInstance) { this.axiosInstance = axiosInstance; this.config = void 0; this.config = { headers: { "Accept": "application/json" }, withCredentials: true }; }
    async get() {
        try {
            const result = await this.axiosInstance.get("/appearance", this.config);
            return result.data;
        }
        catch (err) {
            var _axiosError$response;
            const axiosError = err;
            if ((_axiosError$response = axiosError.response) !== null && _axiosError$response !== void 0 && _axiosError$response.data) {
                throw json({ ...axiosError.response.data }, { status: axiosError.response.status }); // throw new Response(axiosError.response.data.detail, {status: axiosError.response.status})
            }
            throw json({ detail: "Something went wrong." }, { status: 500 });
        }
    }
    async update(data) { try {
        const result = await this.axiosInstance.patch("/appearance", data, this.config);
        return result.data;
    }
    catch (err) {
        var _axiosError$response2;
        const axiosError = err;
        if ((_axiosError$response2 = axiosError.response) !== null && _axiosError$response2 !== void 0 && _axiosError$response2.data) {
            throw json({ ...axiosError.response.data }, { status: axiosError.response.status });
        }
        throw json({ detail: "Something went wrong." }, { status: 500 });
    } }
}
export default AppearanceService;
