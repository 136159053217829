import { json } from "react-router-dom";
class CardAnalyticsService {
    constructor(axiosInstance) { this.axiosInstance = axiosInstance; this.config = void 0; this.config = { headers: { "Accept": "application/json" }, withCredentials: true }; }
    async timeline(params) { const { cardId, ...otherParams } = params; try {
        const result = await this.axiosInstance.get("/cards/".concat(cardId, "/analytics/timeline"), { params: otherParams, ...this.config });
        return result.data;
    }
    catch (err) {
        var _axiosError$response;
        const axiosError = err;
        if ((_axiosError$response = axiosError.response) !== null && _axiosError$response !== void 0 && _axiosError$response.data) {
            throw json({ ...axiosError.response.data }, { status: axiosError.response.status });
        }
        throw json({ detail: "Something went wrong." }, { status: 500 });
    } }
}
export default CardAnalyticsService;
