import { redirect } from "react-router-dom";
import services from "../../../services/Services";
import i18n from "../../../i18nConfig";
export const action = async (_ref) => {
    let { request, params } = _ref;
    try {
        if (request.method === "PATCH" && params.tileId) {
            await services.tiles.update(params.tileId, await request.json());
            return redirect("/".concat(i18n.t("paths.dashboard.index")));
        }
        if (request.method === "DELETE" && params.tileId) {
            return services.tiles.delete(params.tileId); // console.log(response)
            // return redirect(`/${i18n.t("paths.dashboard.index")}`);
        }
    }
    catch (err) {
        const error = err;
        if (error.status === 401) {
            return redirect("/".concat(i18n.t("paths.login.index")));
        }
    }
    return null;
};
