import { json } from "react-router-dom"; // interface UserMultiRequest {
//     (args: UserMultiProps): Promise<UserData[]>
// }
class UserService {
    constructor(axiosInstance) { this.axiosInstance = axiosInstance; }
    async getOneById(userId) { return this.axiosInstance.get("/users/".concat(userId)); }
    async getOneByUsername(username) { return this.axiosInstance.get("/users", { params: { username: username } }); }
    async getMulti(params) { return this.axiosInstance.get("/users", { params: params }); }
    async create(userData) {
        const dataObject = Object.fromEntries(userData.entries());
        return await this.axiosInstance.post('/users', userData, { headers: { "Content-Type": "application/x-www-form-urlencoded" // "Accept": "application/json",
            }, withCredentials: true }); // try {
        //     return await this.axiosInstance.post(
        //         '/users',
        //         {...userData},
        //         {
        //             headers: {
        //                 "Content-Type": "application/json",
        //                 "Accept": "application/json",
        //             }
        //         }
        //     )
        // } catch (err) {
        //     const error = err as AxiosError;
        //     if (!error.response || !(error.response.data instanceof Object)) {
        //         throw json({detail: "Server error"}, {status: 500})
        //     }
        //     if (error.response.status === 400) {
        //         throw json({...error.response.data}, {status: 400});
        //     }
        //     if (error.response.status === 422) {
        //         throw json({detail: "Missing username/e-mail or password"}, { status: 422 });
        //     }
        //     throw json({detail: "Unexpected error occurred"}, {status: error.response.status})
        // }
    }
    async uploadPicture(userId, file) {
        const config = { headers: { "Accept": "application/json" }, withCredentials: true };
        const formData = new FormData();
        formData.append('file', file);
        formData.append('userId', userId);
        try {
            const response = await this.axiosInstance.post("/users/picture", formData, config);
            return response.data;
        }
        catch (err) {
            const error = err;
            if (!error.response || !(error.response.data instanceof Object)) {
                throw { detail: "Server error" };
            } // Możesz dodać więcej obsługi błędów specyficznych dla tego endpointu
            return json({ detail: "Unexpected error occurred" }, { status: error.response.status });
        }
    }
    async deletePicture(userId) {
        const config = { headers: { "Accept": "application/json" }, withCredentials: true };
        try {
            const response = await this.axiosInstance.delete("/users/picture/".concat(userId), config);
            return response.data;
        }
        catch (err) {
            const error = err;
            if (!error.response || !(error.response.data instanceof Object)) {
                throw { detail: "Server error" };
            } // Możesz dodać więcej obsługi błędów specyficznych dla tego endpointu
            return json({ detail: "Unexpected error occurred" }, { status: error.response.status });
        }
    }
    async isUsernameAvailable(username) { const config = { headers: { "Accept": "application/json" }, withCredentials: true }; const url = new URL(this.axiosInstance.defaults.baseURL + "/users/username/attempt"); url.searchParams.set("username", username); try {
        const result = await this.axiosInstance.get(url.toString(), config);
        return result.data;
    }
    catch (err) {
        var _axiosError$response;
        const axiosError = err;
        if ((_axiosError$response = axiosError.response) !== null && _axiosError$response !== void 0 && _axiosError$response.data) {
            throw json({ ...axiosError.response.data }, { status: axiosError.response.status });
        }
        throw json({ detail: "Something went wrong." }, { status: 500 });
    } }
    async isEmailAvailable(email) { const config = { headers: { "Accept": "application/json" }, withCredentials: true }; const url = new URL(this.axiosInstance.defaults.baseURL + "/users/email/attempt"); url.searchParams.set("email", email); try {
        const result = await this.axiosInstance.get(url.toString(), config);
        return result.data;
    }
    catch (err) {
        var _axiosError$response2;
        const axiosError = err;
        if ((_axiosError$response2 = axiosError.response) !== null && _axiosError$response2 !== void 0 && _axiosError$response2.data) {
            throw json({ ...axiosError.response.data }, { status: axiosError.response.status });
        }
        throw json({ detail: "Something went wrong." }, { status: 500 });
    } }
}
export default UserService;
