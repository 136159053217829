const checkFileValidity = _ref => { var _validationRules$minS, _validationRules$maxS; let { validationRules, file } = _ref; if (!file) {
    if (validationRules.required) {
        return validationRules.required.message;
    }
    return;
} if (validationRules.include) {
    var _file$name$split$pop;
    const fileExtension = (_file$name$split$pop = file.name.split('.').pop()) === null || _file$name$split$pop === void 0 ? void 0 : _file$name$split$pop.toLowerCase();
    if (fileExtension && !validationRules.include.formats.includes(fileExtension)) {
        return validationRules.include.message;
    }
} if ((_validationRules$minS = validationRules.minSize) !== null && _validationRules$minS !== void 0 && _validationRules$minS.value && file.size < validationRules.minSize.value) {
    return validationRules.minSize.message;
} if ((_validationRules$maxS = validationRules.maxSize) !== null && _validationRules$maxS !== void 0 && _validationRules$maxS.value && file.size > validationRules.maxSize.value) {
    return validationRules.maxSize.message;
} };
export default checkFileValidity;
